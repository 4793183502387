import { render, staticRenderFns } from "./FAQPage.vue?vue&type=template&id=66c46001&scoped=true&"
import script from "./FAQPage.vue?vue&type=script&lang=js&"
export * from "./FAQPage.vue?vue&type=script&lang=js&"
import style0 from "./FAQPage.vue?vue&type=style&index=0&id=66c46001&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c46001",
  null
  
)

export default component.exports
<template>
  <div class="faq-section">
    <!-- START Header-->
    <header-faq @searchValue="getSearchedValue" />
    <!-- END Header-->
    <div id="content">
      <!-- Collapse -->
      <div
        v-if="!searched"
        class="wrapper-priority"
      >
        <div class="head-title-card">
          Issue yang Sering Dicari
        </div>
        <div
          v-if="!isLoadingToFetch"
          class="wrapper-issues"
        >
          <div
            v-for="(priorities, index) in listChunkPriority"
            :key="index"
            class="coat-issues"
          >
            <div
              v-for="(faq, index2) in priorities"
              id="issues"
              :key="faq.faq_id"
            >
              <CardCollapse
                :index="(index * perChunk) + index2"
                :title="DATA_STYLE.FAQ_DETAIL_CONTENT[faq.category][faq.topic.toLowerCase()].title"
                :sub-title="faq.question"
                :description="faq.answer"
                :icon="getIcon(faq.category, faq.topic)"
                :background-icon="getColor(faq.category, faq.topic)"
                border-top
                is-expand
                expand-disappear
                class="item-collapse"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Collapse -->

      <!-- Search Result -->
      <div
        v-if="searched"
        class="wrapper-priority"
      >
        <div class="pResult">
          <p>Search Result: {{ total_all_data }} Question Related to “{{ keyword }}”</p>
        </div>
        <div
          v-if="!isLoadingToFetch && listResSearched.length>0"
          class="wrapper-result"
        >
          <div
            v-for="(result, index) in listResSearched"
            id="result"
            :key="result.faq_id"
          >
            <CardCollapse
              :index="index"
              :sub-title="highlight(result.question)"
              :description="result.answer"
              is-expand
              expand-disappear
              class="item-collapse"
            />
          </div>
          <div class="content-chevron">
            <input-pagination
              v-model="page"
              :total-all-item="total_all_data"
              :per-page="perPage"
              :disable="false"
              @next="incrementPage"
              @prev="decrementPage"
              @changeData="changePage"
            />
          </div>
        </div>
        <NotFound v-if="listResSearched.length === 0" />
      </div>
      <!-- Search Result -->

      <!-- START Card -->
      <div
        v-for="(data, idx) in FAQ_CONTENT"
        :key="'faq-category-' + idx"
        class="wrapper-card"
      >
        <div
          v-if="data.label.toLowerCase() === 'bantuan'"
          class="head-title-card"
        >
          Bantuan untuk Anda
        </div>
        <div
          v-if="data.label.toLowerCase() === 'apps'"
          class="head-title-card"
        >
          Bantuan terkait Aplikasi
        </div>
        <div class="coat-card">
          <div
            v-for="(perTopic, idx) in data.value"
            :id="data.label.toLowerCase()"
            :key="'faq-topic-'+ idx"
            @click="goToDetail(data.label.toLowerCase(), perTopic.alias)"
          >
            <Cards
              :title="perTopic.alias.replace(/-/g, ' ')"
              :src="getIcon(data.label, perTopic.name)"
              :bg-color="getColor(data.label, perTopic.name)"
              :category="data.label"
              :topic="perTopic.alias"
            />
          </div>
        </div>
      </div>
      <!-- END Card -->
    </div>
  </div>
</template>

<script>
import HeaderFaq from './components/Header';
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import CardCollapse from '@/components/new-card-collapse';
import Cards from './components/Cards';
import FAQ_CONTENT from './app/constants/content';
import DATA_STYLE from './app/constants/getIcon&Color';
import { chunkArray } from './app/util/chunkArray';
import FAQ from './app/usecase/faq.js';
import NotFound from './components/NotFound';
import inputPagination from '@/components/new-pagination/Pagination.vue';

export default {
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  name: 'FAQ',
  components: {
    HeaderFaq,
    CardCollapse,
    Cards,
    NotFound,
    inputPagination,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_faq', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      FAQ_CONTENT,
      DATA_STYLE,
      priority: [],
      isLoadingFetchData: false,
      searched: false,
      keyword: '',
      page: 1,
      perPage: 5,
      ofPages: null,
      total_all_data: null,
      resSearched: [],
      pagePrio: 1,
      perPagePrio: 6,
      orderPrio: 'ASC',
      perChunk: 3,
    };
  },
  computed: {
    isLoadingToFetch() {
      return this.isLoadingFetchData;
    },
    listChunkPriority() {
      const data = this.priority;
      return chunkArray(data, this.perChunk);
    },
    listResSearched() {
      const data = this.resSearched;
      return data;
    },
  },
  async created() {
    await this.fetchPriorityList();
    if (this.$route.query.search) {
      this.getSearchedValue({ page: this.$route.query.page, keyword: this.$route.query.search, searched: true });
    }
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    highlight(words) {
      const regex = new RegExp(this.keyword, 'gi');
      return words.replace(regex, "<mark style='color:#D11F40; background-color:#FEE8E8'>$&</mark>");
    },
    getSearchedValue(value) {
      this.page = (value.page) ? Number(value.page) : 1;
      if (value.keyword) {
        this.fetchSearchResult(value.keyword, this.page);
        this.keyword = value.keyword;
      }
      this.searched = value.searched;
      this.ofPages = null;
    },
    goToDetail(category, topic) {
      this.$router.push(`/faq/${category}/${topic}?page=1`);
    },
    async fetchPriorityList() {
      this.isLoadingFetchData = true;
      const data = await FAQ.getPriority(this.pagePrio, this.perPagePrio, this.orderPrio);
      if (data.err === null) {
        this.isLoadingFetchData = false;
        this.priority = data.data;
      } else {
        this.isLoadingFetchData = false;
      }
    },
    async fetchSearchResult(keyword, page) {
      this.isLoadingFetchData = true;
      const data = await FAQ.getSearched(page, this.perPage, keyword);
      if (data.err === null) {
        this.isLoadingFetchData = false;
        this.resSearched = data.data;
        this.total_all_data = Number(data.pagination.total_all_data);
        this.ofPages = Math.ceil(data.pagination.total_all_data / this.perPage);
      } else {
        this.isLoadingFetchData = false;
      }
    },
    incrementPage() {
      this.page = Number(this.page);
      if (this.page === this.ofPages) {
        return false;
      }
      this.page += 1;
      this.fetchSearchResult(this.keyword, this.page);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${this.page}&search=${this.keyword}`);
      }
    },
    decrementPage() {
      this.page = Number(this.page);
      if (this.page === 1) {
        return false;
      }
      this.page -= 1;
      this.fetchSearchResult(this.keyword, this.page);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${this.page}&search=${this.keyword}`);
      }
    },
    changePage(valueChanged) {
      this.fetchSearchResult(this.keyword, valueChanged);
      if (!this.isSsr()) {
        window.history.replaceState(null, null, `?page=${valueChanged}&search=${this.keyword}`);
      }

      this.page = valueChanged;
    },
    /**
     *
     * @param category {string}
     * @param topic {string}
     * @return {*}
     */
    getIcon(category, topic) {
      return `/images/${DATA_STYLE.FAQ_DETAIL_CONTENT[category][topic.toLowerCase()].src}`;
    },
    /**
     *
     * @param category {string}
     * @param topic {string}
     * @return {string}
     */
    getColor(category, topic) {
      return DATA_STYLE.FAQ_DETAIL_CONTENT[category][topic.toLowerCase()].color;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
